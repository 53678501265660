import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mr-10 ml-10" }
const _hoisted_2 = { class: "mr-10 ml-10" }
const _hoisted_3 = { class: "mr-10 ml-10" }
const _hoisted_4 = { class: "mr-10 ml-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_Plus = _resolveComponent("Plus")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_tabs, {
        modelValue: _ctx.activeName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeName) = $event)),
        onTabClick: _ctx.handleClick
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tab_pane, {
            label: "基本设置",
            name: "first"
          }),
          _createVNode(_component_el_tab_pane, {
            label: "SEO设置",
            name: "second"
          }),
          _createVNode(_component_el_tab_pane, {
            label: "游戏设置",
            name: "three"
          })
        ]),
        _: 1
      }, 8, ["modelValue", "onTabClick"])
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      _createVNode(_component_el_form, {
        ref: "info",
        rules: _ctx.infoRules,
        model: _ctx.info,
        "label-width": "84px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: "网站名称",
            prop: "name"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.info.name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.info.name) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "网站域名" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.info.domain,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.info.domain) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "站长邮箱" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.info.email,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.info.email) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "ICP备案号" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.info.icp,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.info.icp) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "统计代码" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                type: "textarea",
                class: "textarea",
                rows: 3,
                modelValue: _ctx.info.code,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.info.code) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.submitInfo('info')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("保存")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["rules", "model"])
    ])), [
      [_vShow, _ctx.activeIndex === '0'],
      [_directive_loading, _ctx.loading]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_form, {
        ref: "seo",
        model: _ctx.seo,
        rules: _ctx.seoRules,
        "label-width": "84px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: "标题",
            prop: "title"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.seo.title,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.seo.title) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "关键词" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.seo.keywords,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.seo.keywords) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "描述" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                type: "textarea",
                rows: 3,
                class: "textarea",
                modelValue: _ctx.seo.description,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.seo.description) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.submitSeo('seo')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("保存")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ], 512), [
      [_vShow, _ctx.activeIndex === '1']
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
      _createVNode(_component_el_form, {
        ref: "game",
        rules: _ctx.gameRules,
        model: _ctx.game,
        "label-width": "180px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { label: "游戏公告" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.game.notice,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.game.notice) = $event)),
                placeholder: "请输入公告图片地址或点击下方上传图片"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_el_upload, {
                class: "avatar-uploader",
                "on-change": _ctx.onUploadChange,
                "auto-upload": false,
                "show-file-list": false,
                "before-upload": _ctx.beforeUpload
              }, {
                default: _withCtx(() => [
                  (_ctx.game.notice)
                    ? (_openBlock(), _createBlock(_component_el_image, {
                        key: 0,
                        style: {"width":"100%"},
                        src: _ctx.notice
                      }, null, 8, ["src"]))
                    : (_openBlock(), _createBlock(_component_el_icon, {
                        key: 1,
                        class: "avatar-uploader-icon"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Plus)
                        ]),
                        _: 1
                      }))
                ]),
                _: 1
              }, 8, ["on-change", "before-upload"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            label: "新的用户注册赠送钻石",
            prop: "registerIn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tooltip, {
                class: "item",
                effect: "dark",
                content: "请输入数字",
                placement: "top"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.game.registerIn,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.game.registerIn) = $event)),
                    modelModifiers: { number: true }
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            label: "看广告赠送生命数量",
            prop: "giveRatio"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tooltip, {
                class: "item",
                effect: "dark",
                content: "请输入数字",
                placement: "top"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.game.giveRatio,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.game.giveRatio) = $event)),
                    modelModifiers: { number: true }
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            label: "玩游戏消耗生命数量",
            prop: "goldRatio"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tooltip, {
                class: "item",
                effect: "dark",
                content: "请输入数字",
                placement: "top"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.game.goldRatio,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.game.goldRatio) = $event)),
                    modelModifiers: { number: true }
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            label: "用户分享得生命数量",
            prop: "signIn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tooltip, {
                class: "item",
                effect: "dark",
                content: "请输入数字",
                placement: "top"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.game.signIn,
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.game.signIn) = $event)),
                    modelModifiers: { number: true }
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            label: "分享注册成功赠送钻石",
            prop: "registerIn2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tooltip, {
                class: "item",
                effect: "dark",
                content: "请输入数字",
                placement: "top"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.game.registerIn2,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.game.registerIn2) = $event)),
                    modelModifiers: { number: true }
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.submitGame('game')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("保存")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["rules", "model"])
    ])), [
      [_vShow, _ctx.activeIndex === '2'],
      [_directive_loading, _ctx.loading]
    ])
  ], 64))
}