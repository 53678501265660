
import { client } from '@/client';
import { isUrl, loadFile } from '@/utils/tools';
import { ElMessage } from 'element-plus';
import { defineComponent } from "vue";

export default defineComponent({
  name: "home-sys",
  data: () => {
    return {
      loading: true,
      activeName: "first",
      activeIndex: "0",
      info: {
        id: "",
        name: "",
        domain: "",
        email: "",
        icp: "",
        code: "",
      },
      game:{
        id:"",
        notice:"",
        goldRatio: 0,
        giveRatio: 0,
        topRatio: 0,
        registerIn:0,
        registerIn2:0,
        signIn:0,
      },
      seo: {
        id: "",
        title: "",
        keywords: "",
        description: "",
      },
      infoRules: {
        name: [
          { required: true, message: "请输入网站名称", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
      },
      seoRules: {
        title: [
          { required: true, message: "不能为空", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "长度在 2 到 50 个字符",
            trigger: "blur",
          },
        ],
      },
      gameRules:{
        signIn: [
            { required: true, message: "请输入注册赠送金币", trigger: "blur" },
            {
                type:'number',
                message: "请输入数字",
                trigger: "blur",
            },
        ],
        registerIn2: [
            { required: true, message: "请输入注册赠送金币", trigger: "blur" },
            {
                type:'number',
                message: "请输入数字",
                trigger: "blur",
            },
        ],
        registerIn: [
            { required: true, message: "请输入注册赠送金币", trigger: "blur" },
            {
                type:'number',
                message: "请输入数字",
                trigger: "blur",
            },
        ],
        goldRatio: [
            { required: true, message: "请输入金币兑换比例", trigger: "blur" },
            {
                type:'number',
                message: "请输入数字",
                trigger: "blur",
            },
          ],
          giveRatio: [
            { required: true, message: "请输入赠送限额比例", trigger: "blur" },
            {
                type:'number',
                message: "请输入数字",
                trigger: "blur",
            },
          ],
          topRatio: [
            { required: true, message: "请输入充值限额比例", trigger: "blur" },
            {
                type:'number',
                message: "请输入数字",
                trigger: "blur",
            },
          ]
      }
    };
  },
  computed: {
    notice(){
          let path = this.game.notice;
          if (path) {
            if (!isUrl(path)) {
                path = client.options.server + path;
            }
          }
        return path
      }
    },
  created() {
    this.query();
  },
  methods: {
      async onUploadChange(res: { raw: File,name:string }){
        let file = res.raw;
        let isSuss = this.beforeUpload(file);
        if(!isSuss){
          return
        }
        let fileData = await loadFile(file);
        let ret = await client.callApi("pub/UploadImage", {
            file: fileData,
            filename: file.name
        });
        if (ret.isSucc) {
          this.game.notice = ret.res.path;
        } else {
              ElMessage({
                message:ret.err.message,
                type: "error",
              });
        }
      },
      beforeUpload(rawFile:File) {
        let isJPG = rawFile.type === 'image/jpeg';
        let isPNG = rawFile.type === 'image/png';
        let isLt2M = rawFile.size / 1024 / 1024 < 2;
        if (!isJPG && !isPNG) {
            ElMessage({
              message:"只能上传 JPG 或 PNG 格式的图片",
              type: "error",
            });
          return false;
        }
        if (!isLt2M) {
            ElMessage({
              message:"图片大小不能超过 2MB",
              type: "error",
            });
          return false;
        }
        return true;
      },
    handleClick(tab: { index: string; }) {
      this.activeIndex = tab.index;
    },

    //查询
    async query() {
      try {
        // let res = await siteInfo();
        let res = await client.callApi("site/Find", {});
        if (res.isSucc) {
          let data = res.res;
          this.info = {...data , id:data.id.toString()};
          this.seo = {...data,id:data.id.toString()};
          this.game = {
            ...data,
            id:data.id.toString()
          }
        }
          this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    //更新基本信息
    async updateInfo() {
      console.error(this.info);
        let res = await client.callApi("site/UpdateInfo", {
          ...this.info,
        });
        if (res.isSucc) {
          this.info={
            ...res.res,
            id:res.res.id.toString()
          };
          ElMessage({
            message: "更新成功^_^",
            type: "success",
          });
        }
    },

    //更新SEO信息
    async updateSeo() {
        let res = await client.callApi("site/UpdateSeo", {
          ...this.seo,
        });
        if (res.isSucc) {
          this.seo = {
            ...res.res,
            id:res.res.id.toString()
          };
          ElMessage({
            message: "更新成功^_^",
            type: "success",
          });
        }
    },
    //更新SEO信息
    async updateGame() {
        let res = await client.callApi("site/UpdateGame", {
          ...this.game
        });
        if (res.isSucc) {
          this.game = {
            ...res.res,
            id:res.res.id.toString()
          };
          ElMessage({
            message: "更新成功^_^",
            type: "success",
          });
        }else{
          ElMessage({
            message: res.err.message,
            type: "error",
          });
        }
    },

    submitInfo(formName: string) {
      console.error(formName);
      let ref:any = this.$refs[formName];
      if (ref) {
        ref.validate((valid: any) => {
          if (valid) {
            this.updateInfo();
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },

    submitGame(formName: string) {
      console.error(formName);
      let ref:any = this.$refs['game'];
      if (ref) {
        ref.validate((valid: any) => {
          if (valid) {
            this.updateGame();
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },

    submitSeo(formName: string) {
      console.error(formName);
      let ref:any = this.$refs[formName];
      if (ref) {
        ref.validate((valid: any) => {
          if (valid) {
            this.updateSeo();
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    }
  }
})
